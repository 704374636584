import React, {Component} from 'react';
import Fingerprint2 from 'fingerprintjs2';
import {Button, Col} from 'reactstrap';
import Modal from "../../components/UI/Modal/Modal";

class FingerprintVote extends Component {
    constructor(props) {
        super(props);
        this.captcha = null;
        this.state = {
            loadedPost: null,
            fingerprint: '',
            responseToken: '',
            votingButton: 'Jetzt abstimmen!',
            votingReady: true,
            errorModalVisibility: false,
            errorModalMessage: '',
            captchaContainerVisibility: false
        };
    }

    componentDidMount() {
        const options = {};
        let fingerprint;
        Fingerprint2.getPromise(options).then((components) => {
            const values = components.map(({ value }) => value).join("");
            fingerprint = Fingerprint2.x64hash128(values, 31);
            this.setState({ fingerprint });
        });
    }

    onVoteHandler = () => {
        this.fingerprintVote();
    };

    fingerprintVote = () => {
        window.AwsWafIntegration.getToken().catch(err => {
            this.handleUnsuccessfulFingerprintVote(err);
        }).then(token => {
            const { fingerprint } = this.state;
            const { id } = this.props.match.params;
            window.AwsWafIntegration.fetch(`${process.env.REACT_APP_API_URL}/projects/${id}/vote`, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                    'x-aws-waf-token': token,
                },
                body: JSON.stringify({
                    fingerprint: fingerprint
                })
            }).then(response => {
                if (response.status === 405){
                    //hide sms request button
                    this.setState({captchaContainerVisibility: true}, () => {
                        // only then display captcha
                        this.displayCaptchaPuzzle();
                    })
                } else if (response.status === 200){
                    this.setState({captchaContainerVisibility: false, votingReady: false}, () => {
                        if (response !== undefined) {
                            this.setState({
                                votingButton: 'Vielen Dank für Ihre Stimme!'
                            });
                            window.location = "https://chrismon.de/gemeindewettbewerb-2025";
                        } else {
                            this.setState({
                                votingButton: 'Sie haben bereits abgestimmt!'
                            });
                            // throw new Error('Sie haben bereits abgestimmt!');
                            this.handleUnsuccessfulFingerprintVote(response);
                        }
                    })
                } else {
                    this.handleUnsuccessfulFingerprintVote(response);
                }
            }).catch(err => {
                this.handleUnsuccessfulFingerprintVote(err);
            })
        })
    }

    displayCaptchaPuzzle = () => {
        const container = document.querySelector("#captcha-container");
        window.AwsWafCaptcha.renderCaptcha(container, {
            apiKey: process.env.REACT_APP_AWS_WAF_CAPTCHA_KEY,
            onSuccess: this.onCaptchaVerified,
            dynamicWidth: true,
            defaultLocale: "de-DE"
        });
    }

    onCaptchaVerified = () => {
        //Called on Successful Captcha
        //Hide captcha success message to remove confusion in voting process
        // Display voting button as animation progress and redirect are shown within it
        this.setState({
            captchaContainerVisibility: false
        }, () => {
            // only then display captcha
            this.onVoteHandler();
        })

    }

    handleUnsuccessfulFingerprintVote = (responseOrError) => {
        // Check if it's a fetch response or an error object
        if (responseOrError instanceof Response) {
            // Handle fetch Response object
            responseOrError.text().then((responseText) => {
                this.setState({
                    errorModalVisibility: true,
                    errorModalMessage: responseText
                });
            }).catch(err => {
                this.setState({
                    errorModalVisibility: true,
                    errorModalMessage: "Es ist ein unerwarteter Fehler aufgetreten. Bitte versuchen Sie es später noch einmal."
                });
            });
        } else {
            // Handle error object (e.g., network error)
            this.setState({
                errorModalVisibility: true,
                errorModalMessage: "Es ist ein unerwarteter Fehler aufgetreten. Bitte versuchen Sie es später noch einmal."
            });
        }
    }

    closeModalHandler = () => {
        this.setState({ errorModalVisibility: false, errorModalMessage: ''});
    };

    render() {
        return(
            <Col xs="12" sm="6" lg="12">
                <div className={`w-100 ${this.state.captchaContainerVisibility ? "" : "d-none" }`} id="captcha-container"/>
                { !this.state.captchaContainerVisibility && (
                    <Button
                        className={this.state.votingReady ? 'anl_fp--vote' : null}
                        block
                        disabled={!this.state.votingReady}
                        color="primary"
                        onClick={this.onVoteHandler}
                    >
                        {this.state.votingButton}
                    </Button>
                )}
                <Modal modalClosed={this.closeModalHandler} show={this.state.errorModalVisibility}>{this.state.errorModalMessage}</Modal>
            </Col>
        );
    }
}


export default FingerprintVote;
